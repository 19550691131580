import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"

import { Layout, SEO } from '../components'
import theme from '../../config/theme'

const Row = styled.div`
  min-height: 30vw;
  text-align: center;
  padding: 10px;
  @media (min-width: ${theme.breakpoints.tablet}) {

  }
  @media (min-width: ${theme.breakpoints.desktop}) {

  }
`

export default () => {
  const [browser] = useState(typeof window !== 'undefined' && window)
  return (
    browser && (
      <Layout>
        <SEO title="404"/>
        <Row>
          <p style={{ fontSize: '12rem', marginBottom: '0' }}>404</p>
          <p>Lo sentimos, la página solicitada no existe<br /><Link to="/">Click aquí para ir a la Home</Link></p>
        </Row>
      </Layout>
    )
  )
}